import AppContext from 'config/AppContext.js';

// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Link,
  Switch,
  Text,
  useColorMode,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  NumberInput,
  NumberInputField,
  NumberDecrementStepper,
  NumberInputStepper,
  NumberIncrementStepper,
  useColorModeValue,
  Checkbox, 
  CheckboxGroup,
  useToast 
} from "@chakra-ui/react";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import ImageArchitect1 from "assets/img/ImageArchitect1.png";
import ImageArchitect2 from "assets/img/ImageArchitect2.png";
import ImageArchitect3 from "assets/img/ImageArchitect3.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import {
  FaCube,
  FaFacebook,
  FaInstagram,
  FaPenFancy,
  FaPlus,
  FaTwitter,
} from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";

function Profile() {
  const { colorMode } = useColorMode();

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  const { chartsVisibility, setChartsVisibility } = React.useContext(AppContext);

  const toggleChartVisibility = (chartName) => {
    setChartsVisibility(prevState => ({
      ...prevState,
      [chartName]: !prevState[chartName]
    }));
  };

  const toast = useToast();
  const [valveStatus, setValveStatus] = React.useState(false); // Estado inicial baseado no estado atual da válvula

  const fetchValveStatus = async () => {
    try {
      const response = await fetch('https://api-hidrosense.vercel.app/hydrometer/6548170aeb78f9796f2715f0/valve-status', {
        method: 'GET', // Esta linha é opcional para solicitações GET
        headers: {
          // Se precisar de cabeçalhos, como um token de autenticação, eles iriam aqui
        },
      });
      if (!response.ok) {
        throw new Error(`Erro: ${response.status}`);
      }
      const data = await response.json();
      setValveStatus(data.valveState); // Atualize o estado com a resposta da API
    } catch (error) {
      toast({
        title: "Erro ao buscar o estado da válvula.",
        description: error.toString(),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // useEffect para buscar o estado inicial da válvula quando o componente é montado
  React.useEffect(() => {
    fetchValveStatus();
  }, []);

  // Função para alternar o estado da válvula
const toggleValve = async () => {
  // Mostrar toast de loading
  const toastId = "toggle-valve-toast";
  toast({
    id: toastId,
    title: "Processando...",
    description: valveStatus ? "Abrindo válvula..." : "Fechando válvula...",
    status: "info",
    duration: null, // Toast de loading deve permanecer até a operação ser concluída
    isClosable: true,
  });

  try {
    const response = await fetch('https://api-hidrosense.vercel.app/hydrometer/6548170aeb78f9796f2715f0/valve-status', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        // Adicione aqui outros cabeçalhos necessários, como tokens de autenticação
      },
      // Não é necessário enviar body para apenas alternar o estado
    });

    if (!response.ok) {
      throw new Error(`Erro: ${response.status}`);
    }

    const data = await response.json();
    setValveStatus(data.valveState); // Atualize o estado com a resposta da API

    // Fechar o toast de loading
    toast.close(toastId);

    // Mostrar toast de sucesso
    toast({
      title: "Estado da válvula atualizado.",
      description: `A válvula está agora ${data.valveState ? "fechada" : "aberta"}.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });

  } catch (error) {
    // Fechar o toast de loading
    toast.close(toastId);

    // Mostrar toast de erro
    toast({
      title: "Erro ao atualizar o estado da válvula.",
      description: error.toString(),
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb='24px'
        maxH='330px'
        justifyContent={{ sm: "center", md: "space-between" }}
        align='center'
        backdropFilter='blur(21px)'
        boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
        border='1.5px solid'
        borderColor={borderProfileColor}
        bg={bgProfile}
        p='24px'
        borderRadius='20px'>
        <Flex
          align='center'
          mb={{ sm: "10px", md: "0px" }}
          direction={{ sm: "column", md: "row" }}
          w={{ sm: "100%" }}
          textAlign={{ sm: "center", md: "start" }}>
          <Avatar
            me={{ md: "22px" }}
            w='80px'
            h='80px'
            borderRadius='15px'
          />
          <Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
            <Text
              fontSize={{ sm: "lg", lg: "xl" }}
              color={textColor}
              fontWeight='bold'
              ms={{ sm: "8px", md: "0px" }}>
              Usuário Condomino
            </Text>
            <Text
              fontSize={{ sm: "sm", md: "md" }}
              color={emailColor}
              fontWeight='semibold'>
              usuario@condomino.com
            </Text>
          </Flex>
        </Flex>
        
      </Flex>

      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap='22px'>
        <Card p='16px'>
          <CardHeader p='12px 5px' mb='12px'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              Configuração
            </Text>
          </CardHeader>
          <CardBody px='5px'>
            <Flex direction='column'>
              <Text fontSize='sm' color='gray.400' fontWeight='600' mb='20px'>
                CONTA
              </Text>
              <Flex align='center' mb='20px'>
                <Switch colorScheme='blue' me='10px' />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Notificações por E-mail
                </Text>
              </Flex>

              <Flex align='center' mb='20px'>
                <Switch colorScheme='blue' me='10px' />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Notificações via SMS
                </Text>
              </Flex>
              
              <Text
                fontSize='sm'
                color='gray.400'
                fontWeight='600'
                m='6px 0px 20px 0px'>
                SISTEMA
              </Text>

              <Flex align='center' mb='20px'>
                <Switch colorScheme='blue' me='10px' />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Imprimir relatórios via xlsx
                </Text>
              </Flex>
              <Flex align='center' mb='20px'>
                <Switch colorScheme='blue' me='10px' />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Exibir Push-up de Dicas
                </Text>
              </Flex>
              <Flex align='center' mb='20px'>
                <Switch colorScheme='blue' me='10px' />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Receber newsletter Semanal
                </Text>
              </Flex>
            </Flex>
          </CardBody>
        </Card>

        <Card p='16px' my={{ sm: "24px", xl: "0px" }}>
          <CardHeader p='12px 5px' mb='12px'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              Informações de Usuário
            </Text>
          </CardHeader>
          <CardBody px='5px'>
            <Flex direction='column'>
              <Text fontSize='md' color='gray.400' fontWeight='400' mb='30px'>
                Morador condomínio (Nome do condomínio), torre/bloco (ABC123) e apartamento (123). Hidrômetro ID: 0000000.
              </Text>
              <Flex align='center' mb='18px'>
                <Text
                  fontSize='md'
                  color={textColor}
                  fontWeight='bold'
                  me='10px'>
                  Nome:{" "}
                </Text>
                <Text fontSize='md' color='gray.400' fontWeight='400'>
                Usuário Condomino
                </Text>
              </Flex>
              <Flex align='center' mb='18px'>
                <Text
                  fontSize='md'
                  color={textColor}
                  fontWeight='bold'
                  me='10px'>
                  CPF:{" "}
                </Text>
                <Text fontSize='md' color='gray.400' fontWeight='400'>
                000.000.000-00
                </Text>
              </Flex>
              <Flex align='center' mb='18px'>
                <Text
                  fontSize='md'
                  color={textColor}
                  fontWeight='bold'
                  me='10px'>
                  Telefone:{" "}
                </Text>
                <Text fontSize='md' color='gray.400' fontWeight='400'>
                  (00) 00000-0000
                </Text>
              </Flex>
              <Flex align='center' mb='18px'>
                <Text
                  fontSize='md'
                  color={textColor}
                  fontWeight='bold'
                  me='10px'>
                  Email:{" "}
                </Text>
                <Text fontSize='md' color='gray.400' fontWeight='400'>
                usuario@condomino.com
                </Text>
              </Flex>
              
              <FormControl id="amount">
                <FormLabel fontSize='md'
                  color={textColor}
                  fontWeight='bold'
                  me='10px'>Meta Mensal de Consumo (Litros):</FormLabel>
                  <NumberInput max={50} min={10}>
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </FormControl>
            </Flex>
          </CardBody>
        </Card>

        <Card p='16px'>
          <CardHeader p='12px 5px' mb='12px'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
              Gráficos
            </Text>
          </CardHeader>
          <CardBody px='5px'>
            <Flex direction='column'>

              <Flex align='center' mb='20px'>
                <Switch
                  colorScheme='blue' me='10px'
                  isChecked={chartsVisibility.lineChart} 
                  onChange={() => toggleChartVisibility('lineChart')}
                  />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Linhas: Consumido x Planejado
                </Text>
              </Flex>
              <Flex align='center' mb='20px'>
                <Switch 
                  colorScheme='blue' me='10px'
                  isChecked={chartsVisibility.donutChart} 
                  onChange={() => toggleChartVisibility('donutChart')}
                />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Donut: Consumido x Planejado - Mês atual
                </Text>
              </Flex>
              <Flex align='center' mb='20px'>
                <Switch 
                colorScheme='blue' me='10px' 
                isChecked={chartsVisibility.barChart} 
                onChange={() => toggleChartVisibility('barChart')}
                />
                <Text
                  noOfLines={1}
                  fontSize='md'
                  color='gray.400'
                  fontWeight='400'>
                  Barras: Consumo geral semanal
                </Text>
              </Flex>
              
              <CardHeader p='12px 5px' mb='12px'>
            <Text fontSize='lg' color={textColor} fontWeight='bold'>
            Controle das válvulas
            </Text>
          </CardHeader>

              <Flex align='center' mb='20px'>
                  <Switch
                    colorScheme='blue'
                    me='10px'
                    isChecked={valveStatus}
                    onChange={toggleValve}
                  />
                  <Text
                    noOfLines={1}
                    fontSize='md'
                    color='gray.400'
                    fontWeight='400'>
                    Válvula 01: Ativar / Desativar
                  </Text>
                </Flex>
              
            </Flex>
          </CardBody>
        </Card>
        
      </Grid>
    </Flex>
  );
}

export default Profile;
