import React from "react";
import ReactApexChart from "react-apexcharts";
import { DonutChartData, DonutChartOptions } from "variables/charts";

class DonutChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
          
        series: [1300, 876],
        options: {
          tooltip: {
            theme: "dark",
          },
          labels: ['Meta de Consumo', 'Consumido'],
          chart: {
            height: '200px', 
            width: '200px',
            type: 'donut',
          },
          legend: {
            fontWeight: 500,
            labels: {
              colors: '#A0AEC0',
            },
          },
          dataLabels: {
            enabled: false,
            },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                formatter: function(val, opts) {
                  return val + " - " + opts.w.globals.series[opts.seriesIndex]
                } },
            }
          }]
        },
      
      
      };
    }

  

    render() {
      return (
        
    <ReactApexChart options={this.state.options} series={this.state.series} type="donut"/>



      );
    }
  }

    /*this.state = {
      chartData: [],
      chartOptions: {},
    };
  }

  componentDidMount() {
    this.setState({
      chartData: DonutChartData,
      chartOptions: DonutChartOptions,
    });
  }

  render() {
    return (
      <ReactApexChart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="donut"
        width="100%"
        height="100%"
      />
    );
  }
}*/

export default DonutChart;