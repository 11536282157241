import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AppContext from 'config/AppContext.js'; // Adicione o caminho correto
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme.js";
import FloatingButton from 'components/Chatbot/floatingButton';
import Chatbot from 'components/Chatbot/chatBot';

const initialValue = {
  lineChart: true,
  barChart: true,
  donutChart: true,
  // ... outros gráficos que você pode ter ...
};

function AppWrapper() {
  const [chartsVisibility, setChartsVisibility] = React.useState(initialValue);
  const [showChat, setShowChat] = React.useState(false);

  return (
    <ChakraProvider theme={theme} resetCss={false} position="relative">
   <AppContext.Provider value={{ chartsVisibility, setChartsVisibility }}>
     <HashRouter>
       <Switch>
         <Route path={`/auth`} component={AuthLayout} />
         <Route path={`/admin`} component={AdminLayout} />
         <Route path={`/rtl`} component={RTLLayout} />
         <Redirect from={`/`} to="/auth/signin" />
       </Switch>
     </HashRouter>
     {showChat ? <Chatbot onClose={() => setShowChat(false)} /> : <FloatingButton onClick={() => setShowChat(true)} />}
   </AppContext.Provider>
</ChakraProvider>

  );
}

ReactDOM.render(
  <AppWrapper />,
  document.getElementById("root")
);
