// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Td,
  Box,
  Button,
  ButtonGroup,
  useColorModeValue,
  Tabs, 
  TabList, 
  TabPanels, 
  Tab, 
  TabPanel,
  Tfoot,
  Input,
  Divider
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Footer from "components/Footer/Footer";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";
import React from "react";
import { consumoGeralDia, consumoGeralSemana, consumoGeralMes, consumoGeralAno, consumoPeriodo } from "variables/general";

function Tables() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const textTableColor = useColorModeValue("gray.500", "white");

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card p='0px' maxW={{ sm: "425px", md: "100%" }}>
          <Flex direction='column'>
          <Flex align='center'  justify='space-between' p='16px'>
                    <Text fontSize='lg' color={textColor} fontWeight='bold'>
                      Relatório de Consumo Geral
                    </Text>
                      <Button colorScheme="blue" maxH='30px' py='2px'>
                        Imprimir
                      </Button>
                  </Flex>
          <Divider></Divider>
          
          <Tabs justify='space-between'>
            <TabList px='12px' >
              <Tab>Dia</Tab>
              <Tab>Semana</Tab>
              <Tab>Mês</Tab>
              <Tab>Ano</Tab>
              
            </TabList>

            <TabPanels>
              <TabPanel p='0px'>
                <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                
                    <Table>
                      <Thead>
                        <Tr bg={tableRowColor}>
                          <Th color='gray.400' borderColor={borderColor}>
                            Dia
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em L (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Gasto em R$
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {consumoGeralDia.map((el, index, arr) => {
                          return (
                            <Tr key={index}>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                fontWeight='bold'
                                borderColor={borderColor}
                                border={index === arr.length - 1 ? "none" : null}>
                                {el.hora}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLinicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3minicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.GastoEmR$}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                  </Box>
              </TabPanel>
              <TabPanel p='0px'>
                <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    <Table>
                      <Thead>
                        <Tr bg={tableRowColor}>
                          <Th color='gray.400' borderColor={borderColor}>
                            Dia
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em L (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Gasto em R$
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {consumoGeralSemana.map((el, index, arr) => {
                          return (
                            <Tr key={index}>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                fontWeight='bold'
                                borderColor={borderColor}
                                border={index === arr.length - 1 ? "none" : null}>
                                {el.dia}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLinicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3minicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.GastoEmR$}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                  </Box>
              </TabPanel>
              <TabPanel p='0px'>
                <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    <Table>
                      <Thead>
                        <Tr bg={tableRowColor}>
                          <Th color='gray.400' borderColor={borderColor}>
                            Dia
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em L (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Gasto em R$
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {consumoGeralMes.map((el, index, arr) => {
                          return (
                            <Tr key={index}>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                fontWeight='bold'
                                borderColor={borderColor}
                                border={index === arr.length - 1 ? "none" : null}>
                                {el.dia}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLinicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3minicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.GastoEmR$}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                  </Box>
              </TabPanel>
              <TabPanel p='0px'>
                <Box overflow={{ sm: "scroll", lg: "hidden" }}>

                    <Table>
                      <Thead>
                        <Tr bg={tableRowColor}>
                          <Th color='gray.400' borderColor={borderColor}>
                            Dia
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                          Consumo em L (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em L (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (inicio)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Fim)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Consumo em m³ (Total)
                          </Th>
                          <Th color='gray.400' borderColor={borderColor}>
                            Gasto em R$
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {consumoGeralAno.map((el, index, arr) => {
                          return (
                            <Tr key={index}>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                fontWeight='bold'
                                borderColor={borderColor}
                                border={index === arr.length - 1 ? "none" : null}>
                                {el.mes}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLinicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEmLTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3minicio}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mFim}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.ConsumoEm3mTotal}
                              </Td>
                              <Td
                                color={textTableColor}
                                fontSize='sm'
                                border={index === arr.length - 1 ? "none" : null}
                                borderColor={borderColor}>
                                {el.GastoEmR$}
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                  </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          
            
          </Flex>
        </Card>
    </Flex>
  );
}

export default Tables;
