// Chakra imports
import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    Box,
    Button,
    ButtonGroup,
    useColorModeValue,
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel,
    Tfoot,
    Divider
  } from "@chakra-ui/react";
  // Custom components
  import Card from "components/Card/Card.js";
  import TimelineRow from "components/Tables/TimelineRow"
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import Footer from "components/Footer/Footer";
  import TablesProjectRow from "components/Tables/TablesProjectRow";
  import TablesTableRow from "components/Tables/TablesTableRow";
  import React from "react";
  import { consumoGeralDia, consumoGeralSemana, consumoGeralMes, consumoGeralAno, consumoPeriodo, timelineData } from "variables/general";
  

  
  function Tables() {
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
    const textTableColor = useColorModeValue("gray.500", "white");
  
    return (
      <Flex mt='12px' direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card p='0px' maxW={{ sm: "425px", md: "100%" }}>
            <Flex direction='column'>
            <Flex align='center'  justify='space-between' p='24px'>
                      <Text fontSize='lg' color={textColor} fontWeight='bold'>
                        Histórico de notificações
                      </Text>
            </Flex>
            <Divider></Divider>
            <Card p="1rem" maxHeight="100%">
                <CardBody ps="26px" pe="0px" mb="31px" position="relative">
                    <Flex direction="column" gap='10px'>
                    {timelineData.map((row, index, arr) => {
                        return (
                        <TimelineRow
                            logo={row.logo}
                            title={row.title}
                            date={row.date}
                            color={row.color}
                            index={index}
                            arrLength={arr.length}
                        />
                        )
                    })}
                    </Flex>
                </CardBody>
                </Card>
              
            </Flex>
          </Card>
      </Flex>
    );
  }
  
  export default Tables;
  