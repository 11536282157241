export const barChartData = [
  {
    name: "Consumido",
    data: [343, 322, 457, 222, 397, 294, 331],
  },
];

export const barChartOptions = {
  chart: {
    toolbar: {
      show: true,
    },
  },
  tooltip: {
    theme: "dark",
  },
  xaxis: {
    categories: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
    labels: {
      style: {
        colors: "#A0AEC0", 
        fontSize: "12px",
      },
    },
    show: true,
    axisBorder: {
      show: false,
    },
    
  },
  yaxis: {
    show: true,
    color: "#A0AEC0",
    labels: {
      show: true,
      style: {
        colors: "#A0AEC0",
        fontSize: "14px",
      },
    },
  },
  fill: {
    colors: ["#3182CE", "#000000"] 
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  plotOptions: {
    bar: {
      borderRadius: 15,
      columnWidth: "15px",
    },
  },
  responsive: [
    {
      breakpoint: 768,
      options: {
        plotOptions: {
          bar: {
            borderRadius: 0,
          },
        },
      },
    },
  ],
};

export const lineChartData = [
  {
    name: "Consumido",
    data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
  },
  /*{
    name: "Planejado",
    data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
  },*/
];

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: true,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    type: "datetime",
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    axisTicks: {
      show: false
    },
    axisBorder: {
      show: false,
    },
    labels: {
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "#fff",
        fontSize: "12px",
      },
    },
  },
  legend: {
    show: false,
  },
  grid: {
    strokeDashArray: 5,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#fff", "#3182CE"],
  },
  colors: ["#fff", "#3182CE"],
};


export const DonutChartData = [
  {
    series: 88,
  },
];

export const DonutChartOptions = [
  {
    labels: ['Consumido', 'Meta de consumo'],

    chart: {
      width: 380,
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false
    },

    legend: {
      position: 'top',
      horizontalAlign: 'center',
    }
    
    
  },
];