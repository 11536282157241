import React, { useState } from 'react';
import axios from 'axios';
import { ChatIcon } from '@chakra-ui/icons';
import { Box, Input, Button, VStack, Text, Flex, CloseButton, IconButton } from '@chakra-ui/react';

function Chatbot({ onClose }) {
    const [message, setMessage] = useState('');
    const [conversations, setConversations] = useState([]);

    const handleSend = async () => {
        setConversations([...conversations, { type: 'user', text: message }]);
        try {
            const response = await axios.post('/ask', { prompt: message });
            setConversations([...conversations, { type: 'user', text: message }, { type: 'bot', text: response.data.data }]);
        } catch (error) {
            console.error("Error fetching response:", error);
        }
        setMessage('');
    };

    return (
        <Box
            position="fixed"
            bottom="80px"
            right="20px"
            width="320px"
            height="460px"
            border="1px solid"
            borderColor="gray.200"
            borderRadius="md"
            bg="white"
            zIndex="1000"
            overflow="hidden"
        >
            <Box bg='blue.400' justifyContent='flex-end' h="45px">
                <Flex alignItems='center' justifyContent='flex-end' p='6px'>

                    <CloseButton size='md' alignSelf='center' color='white' onClick={onClose}></CloseButton>
                </Flex>
                
                </Box>
            <VStack height="350px" overflowY="scroll" spacing={2} p={3}>
                {conversations.map((conv, index) => (
                    <Flex key={index} w="100%" justify={conv.type === 'user' ? 'flex-end' : 'flex-start'}>
                        <Box maxW="70%" p={2} borderRadius="md" bg={conv.type === 'user' ? 'blue.500' : 'gray.200'} color={conv.type === 'user' ? 'white' : 'black'}>
                            <Text>{conv.text}</Text>
                        </Box>
                    </Flex>
                ))}
            </VStack>

            <Flex align='center' h='65px' bg='blue.400' p={2}>
                <Input value={message} bg='gray.100' color='white' onChange={(e) => setMessage(e.target.value)} flexGrow={1} mr={2} />
                <IconButton onClick={handleSend}  aria-label='enviar' colorScheme='blue.400' icon={<ChatIcon size='lg' color='white'/>} />
            </Flex>
        </Box>
    );
}

export default Chatbot;
