import AppContext from 'config/AppContext.js';

import axios from 'axios';

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  extendTheme,
  useColorModeValue,
  ButtonGroup,
} from "@chakra-ui/react";

import { DatePicker } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState, useEffect } from 'react';

// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import DonutChart from "components/Charts/DonutChart";
import IconBox from "components/Icons/IconBox";

// Custom icons
import {
  CartIcon,
  DocumentIcon,
  TorneiraIcon,
  WaterIcon,
  GlobeIcon,
  WalletIcon,
  CupIcon
} from "components/Icons/Icons.js";

// Variables
import {
  barChartData,
  barChartOptions,
  lineChartData,
  lineChartOptions,
  DonutChartData,
  DonutChartOptions,
} from "variables/charts";

import { pageVisits, consumoPeriodo } from "variables/general";



const breakpoints = {
  base: "0px",
  sm: "375px",
  md: "425px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

// 3. Extend the theme
const theme = extendTheme({ breakpoints });

export default function Dashboard() {
  // Chakra Color Mode
  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");

  const { colorMode } = useColorMode();

  const { chartsVisibility } = React.useContext(AppContext);

  const [selectedDate, setSelectedDate] = useState(null);

  const [currentMonthLiters, setCurrentMonthLiters] = useState(null);
  const [currentMonthMcubic, setCurrentMonthMcubic] = useState(null);
  const [currentMonthBill, setCurrentMonthBill] = useState(null);
  const [currentMonthPrev, setCurrentMonthPrev] = useState(null);

  useEffect(() => {
    // Chamadas Axios
    const fetchData = async () => {
      try {
        const litersResponse = await axios.get('https://api-hidrosense.vercel.app/measurement/total-month-liters');
        console.log(litersResponse.data);
        setCurrentMonthLiters(litersResponse.data.totalLiters);
  
        const mcubicResponse = await axios.get('https://api-hidrosense.vercel.app/measurement/total-month-mcubic');
        // Arredonda para 3 casas decimais e converte para número
        const roundedMcubic = parseFloat(mcubicResponse.data.totalMcubic.toFixed(3));
        setCurrentMonthMcubic(roundedMcubic);
  
        const billResponse = await axios.get('https://api-hidrosense.vercel.app/measurement/total-month-bill');
        // Aqui você pode precisar fazer um arredondamento semelhante, dependendo do que a API retorna
        setCurrentMonthBill(billResponse.data.billingAmount);
  
        const prevResponse = await axios.get('https://api-hidrosense.vercel.app/measurement/total-month-prev');
        // E novamente, pode ser necessário arredondar se estiver recebendo um número com muitas casas decimais
        const roundedPrev = parseFloat(prevResponse.data.projectedConsumption.toFixed(3));
        setCurrentMonthPrev(roundedPrev);
      } catch (error) {
        console.error("Erro ao buscar dados da API", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='20px'>
        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Gasto atual do mês em Litros
                </StatLabel>
                <Flex>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {currentMonthLiters ? `${currentMonthLiters} L` : 'Carregando...'}
                  </StatNumber>
                </Flex>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <TorneiraIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Gasto em relação ao mês anterior
            </Text>
          </Flex>
        </Card>

        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Gasto atual do mês em m³
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {currentMonthMcubic ? `${currentMonthMcubic} m³` : 'Carregando...'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <CupIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
                +3.48%{" "}
              </Text>
              Gasto em relação ao mês anterior
            </Text>
          </Flex>
        </Card>

        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Previsão de Consumo
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                  {currentMonthPrev ? `${currentMonthPrev} m³` : 'Carregando...'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='green.400' fontWeight='bold'>
              ?{" "}
              </Text>
              Baseado no seu histórico de consumo
            </Text>
          </Flex>
        </Card>

        <Card minH='125px'>
          <Flex direction='column'>
            <Flex
              flexDirection='row'
              align='center'
              justify='center'
              w='100%'
              mb='25px'>
              <Stat me='auto'>
                <StatLabel
                  fontSize='xs'
                  color='gray.400'
                  fontWeight='bold'
                  textTransform='uppercase'>
                  Valor da fatura atual
                </StatLabel>
                <Flex>
                  <StatNumber fontSize='lg' color={textColor} fontWeight='bold'>
                    {currentMonthBill ? `R$ ${currentMonthBill}` : 'Carregando...'}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox
                borderRadius='50%'
                as='box'
                h={"45px"}
                w={"45px"}
                bg={iconBlue}>
                <DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color='gray.400' fontSize='sm'>
              <Text as='span' color='red.500' fontWeight='bold'>
                !{" "}
              </Text>
              Valor aproximado referente a tarifa sjeita a alteração atualizada em 10/05/2023
            </Text>
          </Flex>
        </Card>

      </SimpleGrid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "2fr 1fr" }}
        templateRows={{ lg: "repeat(2, auto)" }}
        gap='20px'>

          {chartsVisibility.lineChart && (
           <Card
           bg={
             colorMode === "dark"
               ? "navy.800"
               : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
           }
           p='0px'
           minW={{ sm: "320px", md: "100%", lg: "100%" }}>

          <Flex direction='column' mb='40px' p='28px 22px 0px 22px'>
          <Flex justifyContent='space-between' >
          <Flex direction='column'>
          <Text color='#fff' fontSize='lg' fontWeight='bold' mb='6px'>
               Histórico de consumo
             </Text>
             <Text color='#fff' fontSize='sm'>
               Em metros cúbicos (m³)
             </Text>
           </Flex>

           <Flex direction='row' justify='space-between' align='center' p='12px'>
              <ButtonGroup size='sm' isAttached variant='outline'>
                <Button color='white' onClick={() => handleFilter('day')}>Dia</Button>
                <Button color='white' onClick={() => handleFilter('week')}>Semana</Button>
                <Button color='white' onClick={() => handleFilter('month')}>Mês</Button>
                <Button color='white' onClick={() => handleFilter('year')}>Ano</Button>
              </ButtonGroup>
              <LocalizationProvider color='blue' dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='Escolha uma data'
                  value={selectedDate}
                  onChange={(newValue) => {
                    setSelectedDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              
            </Flex>

          </Flex>
           
           </Flex>

           
           <Box minH='300px'>
               <LineChart
                 chartData={lineChartData}
                 chartOptions={lineChartOptions}
               />
             </Box>
          </Card> 
          )}
        

        {chartsVisibility.donutChart && (
          <Card p='0px' gap='16px'  
          minW={{ sm: "320px", md: "100%", lg: "100%" }}
          >
          <Flex direction='column' p='28px 8px 0px 22px'>
            <Text color={textColor} fontSize='lg' fontWeight='bold'>
              Meta de Consumo Mensal
            </Text>
            
            <Text color='#gray' fontSize='sm'>
              Em metros cúbicos (m3)
            </Text>
          </Flex>
          <Box minH='300px'>
              <DonutChart 
                chartData={DonutChartData} 
                chartOptions={DonutChartOptions}
              />
            </Box>
        </Card> 
        )}
        

        {chartsVisibility.donutChart && (
            <Card p='0px' minW={{ sm: "320px", md: "100%", lg: "100%" }}>
            <Flex direction='column' mb='40px' p='28px 0px 0px 22px'>
              <Text color='gray.400' fontSize='lg' fontWeight='bold' mb='6px'>
                Histórico de consumo
              </Text>
              <Text color={textColor} fontSize='sm' fontWeight='bold'>
                Em Litros (l)
              </Text>
            </Flex>
            <Box minH='300px'>
                <BarChart 
                  chartData={barChartData} 
                  chartOptions={barChartOptions} 
                />
              </Box>
          </Card>
        )}

        <Card p='0px' minW={{ sm: "320px", md: "100%", lg: "100%" }}>
          <Flex direction='column'>
            <Flex align='center' justify='space-between' p='20px'>
            <Flex direction='column' >
            <Text color='gray.400' fontSize='lg' fontWeight='bold' mb='6px'>
              Tabela de Consumo por periodo
            </Text>
          </Flex>
            <Button colorScheme="blue">{new Date().getDate()}/{1 + new Date().getMonth()}/{1900 + new Date().getYear()}</Button>
              
          </Flex>
          </Flex>
          <Box overflow={{ sm: "scroll", lg: "hidden" }}>
          <Table w="100%">
              <Thead>
                <Tr bg={tableRowColor}>
                  <Th color='gray.400' borderColor={borderColor}>
                    Período
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Horário
                  </Th>
                  <Th color='gray.400' borderColor={borderColor}>
                    Consumo
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {consumoPeriodo.map((el, index, arr) => {
                  return (
                    <Tr key={index}>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        fontWeight='bold'
                        borderColor={borderColor}
                        
                        border={index === arr.length - 1 ? "none" : null}>
                        {el.periodo}
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        align="center"
                        justify="space-between"
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center' direction='colum'>
                        {el.horarioInicio}<br></br>{el.horarioFim}
                        </Flex>
                        
                      </Td>
                      <Td
                        color={textTableColor}
                        fontSize='sm'
                        borderColor={borderColor}
                        border={index === arr.length - 1 ? "none" : null}>
                        <Flex align='center'>
                          <Text
                            color={textTableColor}
                            fontWeight='bold'
                            fontSize='sm'
                            me='12px'>{`${el.percentage}%`}</Text>
                          <Progress
                            size='xs'
                            colorScheme={el.color}
                            value={el.percentage}
                            minW={{ sm: "30px", md: "100px", lg: "160px" }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
            
        </Card>
        
      </Grid>
    </Flex>
  );
}
