import React, { useState, useContext } from 'react';

const AppContext = React.createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [chartsVisibility, setChartsVisibility] = useState({
    lineChart: true,
    barChart: true,
    donutChart: true,
    // ... outros gráficos
  });

  const value = {
    chartsVisibility,
    setChartsVisibility,
    // ... outros valores e funções que você pode ter no contexto
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
