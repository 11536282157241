import React from 'react';
import { Button } from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';

function FloatingButton({ onClick }) {
    return (
        <Button
            onClick={onClick}
            position="fixed"
            bottom="20px"
            right="20px"
            borderRadius="full"
            w="60px"
            h="60px"
            fontSize="xl"
            colorScheme="blue"
        >
            <ChatIcon/>
        </Button>
    );
}

export default FloatingButton;
